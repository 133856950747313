@import "./variable.scss";

.courses {
  @include flex-center;
  flex-direction: column;
  background-color: $light-cream;
  padding-bottom: 80px;

  @media (max-width: 1000px) {
    padding-top: 120px;
  }
  &__title {
    color: $dark-red;
    padding-top: 300px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
    a {
      display: flex;
      border: 1px solid $medium-dark-red;
      border-radius: 5px;
      width: 30%;
      box-shadow: $box-shadow;
      transition: transform 0.3s ease-in-out;
      @media (max-width: 1200px) {
        width: 45%;
      }
      @media (max-width: 1000px) {
        width: 100%;
      }
      &:hover {
        transform: scale(1.05);
      }
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}
