@import "./variable.scss";

.header-section {
  background-color: $medium-cream;
  &__wrapped {
    height: 100%;

    &__logo {
      color: $dark-red;
      &:hover {
        color: $dark-red;
        opacity: 0.6;
        cursor: pointer;
      }
    }
    &__menu {
      height: 100%;
      justify-content: end;

      .menu-list {
        display: flex;
        margin: 0;
        padding: 0;

        text-decoration: none;
        color: $dark-red;

        @media (max-width) {
          flex-direction: column;
          align-items: center;
        }
        &__item {
          color: $dark-red;
          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }

          &.active {
            border-bottom: 2px solid $dark-red;
            opacity: 1;
          }
        }
      }
      .menu-language {
        @media (max-width: 991px) {
          display: flex;
          flex-direction: row;
        }
        img {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
          border: 1px solid $dark-red;
          transition: scale 0.15s ease;
          &:hover {
            cursor: pointer;
            scale: 1.1;
          }
        }
      }
    }
  }
}

.offcanvas {
  max-width: 250px;
  background-color: $medium-cream;
  .menu-language {
    @include flex-around;
    flex-direction: row;
  }
}
