@import "./variable.scss";

.portfolio {
  background-color: $light-cream;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;
  &__title {
    color: $dark-red;
  }

  &__cards {
    margin-top: 30px;
    @include flex-around;
    flex-wrap: wrap;
    &__item {
      width: 25%;
      box-shadow: $box-shadow;

      @media (max-width: 1000px) {
        width: 70%;
        margin-bottom: 30px;
      }
      a {
        @include red-button;
        padding: 8px 12px;
      }
      .card-details {
        &__technologies {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          height: 50px;
        }
      }
    }
  }
}
