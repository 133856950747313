body {
  margin: 0;
  padding: 0;
  background: #f5f6f8;
  a {
    text-decoration: none;
    color: black;
  }
}

::-webkit-scrollbar {
  background-color: #edcdbb;
}
::-webkit-scrollbar-thumb {
  background-color: #6b0f29;
  border-radius: 10px;
}
