@import "../variable.scss";

.blog-component {
  background-color: $medium-cream;
  .blog {
    margin-bottom: 120px;
    &__nav {
      h1{
        color: $dark-red;
      }
      .nav {
        &-item {
          
          button {
            background-color: $medium-cream;
            color: $dark-red;
            @media (max-width: 768px) {
              border:1px solid $dark-red;
              border-radius: 20px;
              margin-right: 5px;
              padding: 5px 10px;
              margin-bottom: 5px;
            }
          }
          .active {
            background-color: $light-cream;
            color: $dark-red;
            border: 1px solid $dark-red;
            border-bottom: none;
            @media (max-width: 768px) {
              border: 1px solid $dark-red;
            }
          }
        }
      }
    }
    &__content {
      background-color: $light-cream;
      border-radius: 5px;
      border: 1px solid $dark-red;
      @media (max-width: 768px) {
        height: 480px;
        overflow-x: scroll;
        overflow-y: hidden;
        > div {
          display: flex;
        }
      }
    }
  }
}
