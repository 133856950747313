@import "./variable.scss";

.skills {
  position: relative;
  @include flex-center;
  flex-direction: column;
  background-color: $light-cream;
  padding-top: 270px;
  padding-bottom: 50px;

  @media (max-width: 1000px) {
    padding-top: 120px;
  }
  &__title {
    color: $dark-red;
  }
  &__items {
    width: 80%;
    &__progress {
      padding: 0 10px;
      .progress {
        box-shadow: $box-shadow;
        &-bar {
          background-color: $rose-pink;
        }
      }
    }
  }
  &__btn {
    button {
      @include red-button;
    }
  }
}
