@import "../variable.scss";

.blog-item {
  background-color: $medium-cream;
  padding-top: 200px;

  &__container {
    border-radius: 5px;
    border: 1px solid $dark-red;
    margin-bottom: 180px;
    background-color: white;
    position: relative;
    &__button {
      @include red-button;
      @include flex-center;
      position: absolute;
      top: -5%;
      left: 0%;
      div {
        margin-left: 10px;
      }
      @media (max-width: 991px) {
        top: -4%;
      }
      @media (max-width: 767px) {
        top: -4%;
      }
      @media (max-width: 565px) {
        left: 50%;
        transform: translate(-50%, 0);
        width: 80%;
      }
      @media (max-width: 410px) {
        top: -5%;
      }
      @media (max-width: 320px) {
        top: -4%;
        
      }
    }

    &__title {
      font-size: 2rem;
      color: $dark-red;
      margin-bottom: 20px;
    }
    &__date {
      font-size: 20px;
      color: $medium-dark-red;
      margin-bottom: 20px;
    }
    &__content {
      font-size: 20px;
      color: $medium-dark-red;
    }
    &__img {
      width: 100%;
      height: 400px;
      margin-bottom: 20px;
    }
  }
}
