@import "../variable.scss";

.blog-card {
  @include flex-between;
  flex-direction: row-reverse;
  align-items: start;
  background-color: $light-cream;
  border: 1px solid $dark-red;
  box-shadow: $box-shadow;

  @media (max-width: 768px) {
    height: 360px;
    width: 280px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 440px) {
    width: 260px;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 300ms ease-in-out;
    background-color: $light-cream2;
  }
  img {
    height: 150px;
    width: 150px;
    object-fit: fill;
    @media (max-width: 768px) {
        width: 100%;
    }
  }
  .card {
    &-text {
      height: 50px;
      overflow: hidden;
    }
    &-title{
        @media (max-width: 768px) {
            height: 60px;
            overflow: hidden;
        }
        @media (max-width: 440px) {
            height: 55px;
          }
    }
  }
}
