@import "./variable.scss";

.about-me {
  @include flex-between;
  position: relative;
  background-color: $light-cream;
  padding-top: 160px;
  padding-bottom: 160px;
  @media (max-width: 1000px) {
    padding-top: 60px;
  }
  &__left {
    position: relative;
    width: 30%;
    @media (max-width: 800px) {
      width: 0%;
    }
  }
  &__right {
    width: 80%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  &__img {
    height: 500px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    opacity: 0.3;
    @media (max-width: 1200px) {
      height: 350px;
    }
    @media (max-width: 1000px) {
      height: 330px;
    }
    @media (max-width: 440px) {
      height: 300px;
    }
    @media (max-width: 350px) {
      height: 270px;
    }
  }
  &__bottom {
    @include svg-design;
    bottom: -6px;
  }
}
