@import "./variable.scss";

.copyright {
  height: 40px;
  background-color: $dark-red;
  position: relative;
  &__svg {
    @include svg-design;
    bottom: 39px;
  }

  &__text {
    display: flex;
    justify-content: center;
    color: $light-cream;
    @media (max-width: 700px) {
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
    }
  }
}
