@import "./variable.scss";

.home-section {
  background-color: $medium-cream;
  height: 800px;
  @include flex-around;
  position: relative;

  @media (max-width: 1200px) {
    height: 800px;
    padding: 0 70px;
  }
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    height: 1100px;
  }
  @media (max-width: 800px) {
    height: 1000px;
  }
  @media (max-width: 440px) {
    height: 950px;
  }
  @media (max-width: 380px) {
    height: 950px;
  }

  &__left {
    &__btn {
      @include red-button;
    }
    @media (max-width: 1200px) {
      width: 50%;
    }
    @media (max-width: 800px) {
      width: 100%;
    }
    @media (max-width: 380px) {
      width: 220px;
    }
  }

  &__right {
    position: relative;
    width: 30%;
    height: 60%;
    @media (max-width: 1200px) {
      width: 40%;
      height: 50%;
    }
    @media (max-width: 1000px) {
      width: 70%;
      height: 45%;
    }
    @media (max-width: 800px) {
      width: 100%;
      height: 35%;
    }
    @media (max-width: 440px) {
      padding-top: 20px;
    }
    @media (max-width: 380px) {
      width: 220px;
      padding-top: 30px;
    }

    &__background {
      background-color: $rose-pink;
      border-bottom-right-radius: 40%;
      border-top-left-radius: 40%;

      height: 500px;
      width: 450px;

      position: absolute;
      z-index: 0;
      @media (max-width: 1200px) {
        width: 100%;
        height: 400px;
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 380px;
      }
      @media (max-width: 440px) {
        width: 100%;
        height: 300px;
      }
      @media (max-width: 380px) {
        width: 100%;
        height: 280px;
        width: 100%;
      }

      img {
        width: 70%;
      }
    }
    .logo-pic {
      height: 500px;
      position: absolute;
      z-index: 1;
      @media (max-width: 1200px) {
        height: 400px;
      }
      @media (max-width: 1000px) {
        height: 380px;
      }
      @media (max-width: 440px) {
        height: 300px;
      }
      @media (max-width: 380px) {
        height: 280px;
      }
    }
  }

  &__svg {
    @include svg-design;
    bottom: -5px;
  }
}
