@import "../variable.scss";

.card-item {
  background-color: $light-cream !important;
  border: 1px solid $dark-red !important;
  box-shadow: $box-shadow;

  // opacity: 0.4;
  // animation: fade-in linear;
  // animation-timeline: view();

  // @keyframes fade-in {
  //   from {
  //     opacity: 0.4;
  //   }
  //   to {
  //     opacity: 1;
  //   }
  // }


  @media (max-width: 766px) {
    margin: 0 0 50px 0 !important;
  }

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -58px;
    width: 15px;
    height: 15px;
    background: $dark-red;
    border-radius: 50%;
    @media (max-width: 766px) {
      content: none;
    }
  }
  &__body {
    position: relative;
    span {
      color: $dark-red;
      font-weight: bold;
    }
    &__description {
      .more {
        cursor: pointer;
      }
    }
    &__skills-btn {
      .buttons {
        display: flex;
        justify-content: flex-end;
        a {
          @include red-button;
          padding: 7px 10px;
        }
      }
    }
    img {
      position: absolute;
      border: 1px solid $dark-red;
      left: 0px;
      top: -32px;
    }
  }
}
