@import "./variable.scss";

.contact-me {
  position: relative;
  @include flex-around;
  background-color: $medium-cream;
  padding: 140px 0;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  &__info {
    display: flex;
    align-items: center;
    flex-direction: column;
    // @media (max-width: 1200px) {
    //   display: flex;
    //   align-items: center;
    //   flex-direction: column;
    // }
    &__title {
      color: $dark-red;
      strong {
        color: $dark-red;
      }
    }
    &__btn {
      margin-bottom: 20px;
      a {
        @include red-button;
      }
    }
  }
  &__form {
    @include flex-between;
    flex-direction: column;
    width: 40%;
    @media (max-width: 1200px) {
      width: 80%;
    }
    input,
    textarea {
      background-color: $light-cream;
      border-color: $dark-red;
      border-width: medium;
      box-shadow: $box-shadow;
    }
    textarea {
      height: 200px;
    }
    button {
      @include red-button;
    }
  }
}
