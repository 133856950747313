//color range
$dark-red: #6b0f29;
$medium-dark-red: #981d40;
$light-cream: #f8f3f0;
$light-cream2:#f2e7e1;
$medium-cream: #edcdbb;
$rose-pink: #dd7e7c;

$box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);

//mixins
@mixin flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin red-button {
  background-color: $dark-red;
  color: $light-cream;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: $box-shadow;
  &:hover {
    background: $medium-dark-red;
    color: $light-cream;
  }
}
@mixin svg-design {
  position: absolute;
  z-index: 2;
  width: 100%;
}
