@import "../variable.scss";

.card-item-section {
  &__title {
    color: $dark-red;
  }
  &__items {
    border-left: thick $dark-red solid;
    @media (max-width: 766px) {
      border-left: none;
    }
  }
}
