.header-section {
  background-color: #edcdbb;
}
.header-section__wrapped {
  height: 100%;
}
.header-section__wrapped__logo {
  color: #6b0f29;
}
.header-section__wrapped__logo:hover {
  color: #6b0f29;
  opacity: 0.6;
  cursor: pointer;
}
.header-section__wrapped__menu {
  height: 100%;
  justify-content: end;
}
.header-section__wrapped__menu .menu-list {
  display: flex;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: #6b0f29;
}
@media (max-width) {
  .header-section__wrapped__menu .menu-list {
    flex-direction: column;
    align-items: center;
  }
}
.header-section__wrapped__menu .menu-list__item {
  color: #6b0f29;
}
.header-section__wrapped__menu .menu-list__item:hover {
  cursor: pointer;
  opacity: 0.6;
}
.header-section__wrapped__menu .menu-list__item.active {
  border-bottom: 2px solid #6b0f29;
  opacity: 1;
}
@media (max-width: 991px) {
  .header-section__wrapped__menu .menu-language {
    display: flex;
    flex-direction: row;
  }
}
.header-section__wrapped__menu .menu-language img {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #6b0f29;
  transition: scale 0.15s ease;
}
.header-section__wrapped__menu .menu-language img:hover {
  cursor: pointer;
  scale: 1.1;
}

.offcanvas {
  max-width: 250px;
  background-color: #edcdbb;
}
.offcanvas .menu-language {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.home-section {
  background-color: #edcdbb;
  height: 800px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
@media (max-width: 1200px) {
  .home-section {
    height: 800px;
    padding: 0 70px;
  }
}
@media (max-width: 1000px) {
  .home-section {
    flex-direction: column-reverse;
    height: 1100px;
  }
}
@media (max-width: 800px) {
  .home-section {
    height: 1000px;
  }
}
@media (max-width: 440px) {
  .home-section {
    height: 950px;
  }
}
@media (max-width: 380px) {
  .home-section {
    height: 950px;
  }
}
.home-section__left__btn {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
.home-section__left__btn:hover {
  background: #981d40;
  color: #f8f3f0;
}
@media (max-width: 1200px) {
  .home-section__left {
    width: 50%;
  }
}
@media (max-width: 800px) {
  .home-section__left {
    width: 100%;
  }
}
@media (max-width: 380px) {
  .home-section__left {
    width: 220px;
  }
}
.home-section__right {
  position: relative;
  width: 30%;
  height: 60%;
}
@media (max-width: 1200px) {
  .home-section__right {
    width: 40%;
    height: 50%;
  }
}
@media (max-width: 1000px) {
  .home-section__right {
    width: 70%;
    height: 45%;
  }
}
@media (max-width: 800px) {
  .home-section__right {
    width: 100%;
    height: 35%;
  }
}
@media (max-width: 440px) {
  .home-section__right {
    padding-top: 20px;
  }
}
@media (max-width: 380px) {
  .home-section__right {
    width: 220px;
    padding-top: 30px;
  }
}
.home-section__right__background {
  background-color: #dd7e7c;
  border-bottom-right-radius: 40%;
  border-top-left-radius: 40%;
  height: 500px;
  width: 450px;
  position: absolute;
  z-index: 0;
}
@media (max-width: 1200px) {
  .home-section__right__background {
    width: 100%;
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .home-section__right__background {
    width: 100%;
    height: 380px;
  }
}
@media (max-width: 440px) {
  .home-section__right__background {
    width: 100%;
    height: 300px;
  }
}
@media (max-width: 380px) {
  .home-section__right__background {
    width: 100%;
    height: 280px;
    width: 100%;
  }
}
.home-section__right__background img {
  width: 70%;
}
.home-section__right .logo-pic {
  height: 500px;
  position: absolute;
  z-index: 1;
}
@media (max-width: 1200px) {
  .home-section__right .logo-pic {
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .home-section__right .logo-pic {
    height: 380px;
  }
}
@media (max-width: 440px) {
  .home-section__right .logo-pic {
    height: 300px;
  }
}
@media (max-width: 380px) {
  .home-section__right .logo-pic {
    height: 280px;
  }
}
.home-section__svg {
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: -5px;
}

.about-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #f8f3f0;
  padding-top: 160px;
  padding-bottom: 160px;
}
@media (max-width: 1000px) {
  .about-me {
    padding-top: 60px;
  }
}
.about-me__left {
  position: relative;
  width: 30%;
}
@media (max-width: 800px) {
  .about-me__left {
    width: 0%;
  }
}
.about-me__right {
  width: 80%;
}
@media (max-width: 800px) {
  .about-me__right {
    width: 100%;
  }
}
.about-me__img {
  height: 500px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  opacity: 0.3;
}
@media (max-width: 1200px) {
  .about-me__img {
    height: 350px;
  }
}
@media (max-width: 1000px) {
  .about-me__img {
    height: 330px;
  }
}
@media (max-width: 440px) {
  .about-me__img {
    height: 300px;
  }
}
@media (max-width: 350px) {
  .about-me__img {
    height: 270px;
  }
}
.about-me__bottom {
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: -6px;
}

.experiences-educations {
  background-color: #edcdbb;
  padding-top: 70px;
}
.experiences-educations__svg {
  position: absolute;
  z-index: 2;
  width: 100%;
}

.portfolio {
  background-color: #f8f3f0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 120px;
}
.portfolio__title {
  color: #6b0f29;
}
.portfolio__cards {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.portfolio__cards__item {
  width: 25%;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
@media (max-width: 1000px) {
  .portfolio__cards__item {
    width: 70%;
    margin-bottom: 30px;
  }
}
.portfolio__cards__item a {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
  padding: 8px 12px;
}
.portfolio__cards__item a:hover {
  background: #981d40;
  color: #f8f3f0;
}
.portfolio__cards__item .card-details__technologies {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  height: 50px;
}

.skills {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f8f3f0;
  padding-top: 270px;
  padding-bottom: 50px;
}
@media (max-width: 1000px) {
  .skills {
    padding-top: 120px;
  }
}
.skills__title {
  color: #6b0f29;
}
.skills__items {
  width: 80%;
}
.skills__items__progress {
  padding: 0 10px;
}
.skills__items__progress .progress {
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
.skills__items__progress .progress-bar {
  background-color: #dd7e7c;
}
.skills__btn button {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
.skills__btn button:hover {
  background: #981d40;
  color: #f8f3f0;
}

.contact-me {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #edcdbb;
  padding: 140px 0;
}
@media (max-width: 1200px) {
  .contact-me {
    flex-direction: column;
  }
}
.contact-me__info {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-me__info__title {
  color: #6b0f29;
}
.contact-me__info__title strong {
  color: #6b0f29;
}
.contact-me__info__btn {
  margin-bottom: 20px;
}
.contact-me__info__btn a {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
.contact-me__info__btn a:hover {
  background: #981d40;
  color: #f8f3f0;
}
.contact-me__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 40%;
}
@media (max-width: 1200px) {
  .contact-me__form {
    width: 80%;
  }
}
.contact-me__form input,
.contact-me__form textarea {
  background-color: #f8f3f0;
  border-color: #6b0f29;
  border-width: medium;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
.contact-me__form textarea {
  height: 200px;
}
.contact-me__form button {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
.contact-me__form button:hover {
  background: #981d40;
  color: #f8f3f0;
}

.copyright {
  height: 40px;
  background-color: #6b0f29;
  position: relative;
}
.copyright__svg {
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 39px;
}
.copyright__text {
  display: flex;
  justify-content: center;
  color: #f8f3f0;
}
@media (max-width: 700px) {
  .copyright__text {
    font-size: 14px;
  }
}
@media (max-width: 440px) {
  .copyright__text {
    font-size: 12px;
  }
}

.card-item {
  background-color: #f8f3f0 !important;
  border: 1px solid #6b0f29 !important;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
@media (max-width: 766px) {
  .card-item {
    margin: 0 0 50px 0 !important;
  }
}
.card-item:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -58px;
  width: 15px;
  height: 15px;
  background: #6b0f29;
  border-radius: 50%;
}
@media (max-width: 766px) {
  .card-item:before {
    content: none;
  }
}
.card-item__body {
  position: relative;
}
.card-item__body span {
  color: #6b0f29;
  font-weight: bold;
}
.card-item__body__description .more {
  cursor: pointer;
}
.card-item__body__skills-btn .buttons {
  display: flex;
  justify-content: flex-end;
}
.card-item__body__skills-btn .buttons a {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
  padding: 7px 10px;
}
.card-item__body__skills-btn .buttons a:hover {
  background: #981d40;
  color: #f8f3f0;
}
.card-item__body img {
  position: absolute;
  border: 1px solid #6b0f29;
  left: 0px;
  top: -32px;
}

.card-item-section__title {
  color: #6b0f29;
}
.card-item-section__items {
  border-left: thick #6b0f29 solid;
}
@media (max-width: 766px) {
  .card-item-section__items {
    border-left: none;
  }
}

.courses {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f8f3f0;
  padding-bottom: 80px;
}
@media (max-width: 1000px) {
  .courses {
    padding-top: 120px;
  }
}
.courses__title {
  color: #6b0f29;
  padding-top: 300px;
}
.courses__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: 50px;
}
.courses__items a {
  display: flex;
  border: 1px solid #981d40;
  border-radius: 5px;
  width: 30%;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
  transition: transform 0.3s ease-in-out;
}
@media (max-width: 1200px) {
  .courses__items a {
    width: 45%;
  }
}
@media (max-width: 1000px) {
  .courses__items a {
    width: 100%;
  }
}
.courses__items a:hover {
  transform: scale(1.05);
}
.courses__items a img {
  width: 100%;
  border-radius: 5px;
}

.blog-component {
  background-color: #edcdbb;
}
.blog-component .blog {
  margin-bottom: 120px;
}
.blog-component .blog__nav h1 {
  color: #6b0f29;
}
.blog-component .blog__nav .nav-item button {
  background-color: #edcdbb;
  color: #6b0f29;
}
@media (max-width: 768px) {
  .blog-component .blog__nav .nav-item button {
    border: 1px solid #6b0f29;
    border-radius: 20px;
    margin-right: 5px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
}
.blog-component .blog__nav .nav-item .active {
  background-color: #f8f3f0;
  color: #6b0f29;
  border: 1px solid #6b0f29;
  border-bottom: none;
}
@media (max-width: 768px) {
  .blog-component .blog__nav .nav-item .active {
    border: 1px solid #6b0f29;
  }
}
.blog-component .blog__content {
  background-color: #f8f3f0;
  border-radius: 5px;
  border: 1px solid #6b0f29;
}
@media (max-width: 768px) {
  .blog-component .blog__content {
    height: 480px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .blog-component .blog__content > div {
    display: flex;
  }
}

.blog-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  align-items: start;
  background-color: #f8f3f0;
  border: 1px solid #6b0f29;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
}
@media (max-width: 768px) {
  .blog-card {
    height: 360px;
    width: 280px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 440px) {
  .blog-card {
    width: 260px;
  }
}
.blog-card:hover {
  transform: scale(1.02);
  transition: all 300ms ease-in-out;
  background-color: #f2e7e1;
}
.blog-card img {
  height: 150px;
  width: 150px;
  object-fit: fill;
}
@media (max-width: 768px) {
  .blog-card img {
    width: 100%;
  }
}
.blog-card .card-text {
  height: 50px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .blog-card .card-title {
    height: 60px;
    overflow: hidden;
  }
}
@media (max-width: 440px) {
  .blog-card .card-title {
    height: 55px;
  }
}

.blog-item {
  background-color: #edcdbb;
  padding-top: 200px;
}
.blog-item__container {
  border-radius: 5px;
  border: 1px solid #6b0f29;
  margin-bottom: 180px;
  background-color: white;
  position: relative;
}
.blog-item__container__button {
  background-color: #6b0f29;
  color: #f8f3f0;
  padding: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: none;
  box-shadow: 0 3px 6px 0 hsla(9, 13%, 51%, 0.42);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5%;
  left: 0%;
}
.blog-item__container__button:hover {
  background: #981d40;
  color: #f8f3f0;
}
.blog-item__container__button div {
  margin-left: 10px;
}
@media (max-width: 991px) {
  .blog-item__container__button {
    top: -4%;
  }
}
@media (max-width: 767px) {
  .blog-item__container__button {
    top: -4%;
  }
}
@media (max-width: 565px) {
  .blog-item__container__button {
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
  }
}
@media (max-width: 410px) {
  .blog-item__container__button {
    top: -5%;
  }
}
@media (max-width: 320px) {
  .blog-item__container__button {
    top: -4%;
  }
}
.blog-item__container__title {
  font-size: 2rem;
  color: #6b0f29;
  margin-bottom: 20px;
}
.blog-item__container__date {
  font-size: 20px;
  color: #981d40;
  margin-bottom: 20px;
}
.blog-item__container__content {
  font-size: 20px;
  color: #981d40;
}
.blog-item__container__img {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

